import { ukApiHelper } from "./apiHelper";

export const socialLoginFunc = (data, type, locale) => {
    return new Promise((resolve, reject) => {
        const email = data.profileObj.email;
        const ID = data.googleId;

        let formData = new FormData();
        formData.append('email', email);
        formData.append('sn_id', ID);
        formData.append('sn_type', type);

        ukApiHelper('socialLogin','POST', formData, null, locale).then(res => {
            resolve(res);
        }).catch(err => {
            // console.log('err');
            reject(err);
        });
    });
}