import React, {useState} from "react";
import {useRouter} from "next/router";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// API and JWT helper
import {apiHelper, ukApiHelper} from "../../helper/apiHelper";
import {jwtDecode} from "../../helper/jwtHelper";
import {socialLoginFunc} from '../../helper/socialMediaAuthHelper';
import {DEFAULT_LOCALES_LIST, US} from '../../helper/constantHelper';
// react slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// components
import dynamic from 'next/dynamic';
// img
import logo from "../../public/logo.svg";
import mobileLogo from "../../public/mobileLogo.svg";
import cheapestMarket from "../../public/account/cheapest-market.svg";
import expertWriter from "../../public/account/expert-writer.svg";
import onTimeDelivery from "../../public/account/on-time-delivery.svg";
import Link from "next/link";
import {HidePasswordIcon, ShowPasswordIcon} from "../icons";
import Image from "next/image";
import useDeviceWidth from "../../helper/useDeviceWidth";
import {decodeTokenToFormData, setInterComData} from "../../helper/utilHelper";
// scss
import styles from "./account.module.scss";
import accountLeftCSS from "./accountLeft.module.scss"
import ReactGoogleLogin from "react-google-login";
const Accountleft = dynamic(() => import("./accountLeft"));

const Account = (props) => {
  // Email and password
  const [enteredEmail, setEnteredEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [emailInValidMsg, setEmailInValidMsg] = useState("");
  const [passwordInValidMsg, setPasswordInValidMsg] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [detailIsValid, setDetailIsValid] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  const router = useRouter();
  const { currLocale } = props;

  const { width } = useDeviceWidth();
  const emailChangeHandler = (event) => {
    setDetailIsValid('');
    setEnteredEmail(event.target.value);

    if (router.pathname === "/forget-password") {
      setFormIsValid(event.target.value.includes("@"));
      setforgotPwdErrormsg('');
    } else {
      setFormIsValid(
        event.target.value.includes("@") && enteredPassword.trim().length > 3
      );
    }
  }

  const passwordChangeHandler = (event) => {
    setDetailIsValid('');
    setEnteredPassword(event.target.value);

    setFormIsValid(
      enteredEmail.includes("@") && event.target.value.trim().length > 3
    );
  }

  const validateEmailHandler = () => {
    setEmailIsValid(enteredEmail.includes("@"));
    setEmailInValidMsg("Email should be either valid or not be blank.");
  }

  const validatePasswordHandler = () => {
    setPasswordIsValid(enteredPassword.trim().length > 3);
    setPasswordInValidMsg(
      "Password should either greater than 3 characters long or not be blank"
    );
  }

  const setUserLoggedInData = (user_token) => {
    const now = new Date();
    const item = {
      value: user_token,
      expiry: now.getTime() + 12 * 60 * 60 * 1000
    };
    localStorage.setItem("token", JSON.stringify(item));
    // set localstorage
    if (
      !localStorage.getItem("calculator_service") ||
      localStorage.getItem("calculator_service") === ""
    ) {
      localStorage.setItem(
        "calculator_service",
        JSON.stringify({ id: 3, label: "Writing" })
      );
    }
    if (
      !localStorage.getItem("calculator_pages") ||
      localStorage.getItem("calculator_pages") === ""
    ) {
      localStorage.setItem(
        "calculator_pages",
        JSON.stringify({ value: 1, label: "Essay (Any Type)" })
      );
    }

    if (
      !localStorage.getItem("calculator_deadline") ||
      localStorage.getItem("calculator_deadline") === ""
    ) {
      localStorage.setItem(
        "calculator_deadline",
        JSON.stringify({
          digit: '',
          durations: '',
          label: 'Deadline'
        })
      );
    }
    if (
      !localStorage.getItem("calculator_word") ||
      localStorage.getItem("calculator_word") === ""
    ) {
      localStorage.setItem(
        "calculator_word",
        JSON.stringify({ id: 1, label: "280 words / 1 page" })
      );
    }
    setDetailIsValid("");
    setOrderData();
  }

  const afterLoginPages = ['/profile', '/balance', '/level', '/referral', '/my-orders', '/my-orders/order-details', '/order', '/invoice'];

  const expireToken = () => {
    if (!!window.Intercom && typeof window !== 'undefined') {
      window.Intercom('boot', {
        app_id: 'je6f9lsz'
      });
    }

    if (afterLoginPages?.includes(pathname) && pathname !== '/order') {
      const logoutPath = `/logout`;
      localStorage.removeItem('token');
      router.push(logoutPath);
    }
  }

  const callInterCom = () => {
    const loginToken = localStorage.getItem("token");

      if (loginToken) {
        const convertedToken = JSON.parse(loginToken);
        const now = new Date();
        const decodeToken = convertedToken ? jwtDecode(convertedToken.value) : null;

        if (decodeToken?.exp && (decodeToken.exp * 1000) >= now.getTime() && now.getTime() <= convertedToken.expiry) {
          if (!!window.Intercom && typeof window !== 'undefined') {
            window.Intercom('boot', {
              app_id: 'je6f9lsz'
            });
          }
          setInterComData(convertedToken.value);
        } else {
          expireToken();
        }
      } else {
        expireToken();
      }
  }

  const loginSubmitHandler = (event) => {
    event.preventDefault();
    setApiLoader(true);
    const formData = new FormData();

    formData.append("email", enteredEmail);
    formData.append("password", enteredPassword);

    apiHelper("checkLogin", "POST", formData, null)
      .then((res) => {
        const response = res.data;
        setApiLoader(false);
        if (response.status) {
          // Set Data in localstrorage
          setUserLoggedInData(response.data.user_token);
          callInterCom();
        } else {
          setDetailIsValid(response.message);
        }
      })
      .catch((error) => console.error(`Error: ${error}`));
  }

  const signUpSubmitHandler = (event) => {
    event.preventDefault();
    setApiLoader(true);
    const formData = new FormData();

    formData.append("email", enteredEmail);
    formData.append("password", enteredPassword);

    ukApiHelper("createAccountV1", "POST", formData, null)
    .then((res) => {
      setApiLoader(false);
      if (res.data.status) {
        setUserLoggedInData(res.data.data);
        callInterCom();
      } else {
        setDetailIsValid(res.data.message);
      }
    })
    .catch((error) => console.error(`Error: ${error}`));

  }

  const [forgotPwdErrormsg, setforgotPwdErrormsg] = useState("");
  const [forgotPwdSuccessmsg, setforgotPwdSuccessmsg] = useState("");
  const forgetPassword = (event) => {
    event.preventDefault();
    if (emailIsValid) {
      setApiLoader(true);
      const formData = new FormData();
      formData.append("email", enteredEmail);
      apiHelper("forgotPassword", "POST", formData, null)
        .then((res) => {
          setApiLoader(false);
          if (res.data.status) {
            toast.success(res.message);
            setTimeout(async () => {
              await router.push(`/login`);
            }, 5000);
            setforgotPwdSuccessmsg(<span className="msgText successMsg">{res.data.message}</span>)
          } else {
            setforgotPwdErrormsg(
              <span className="msgText errorMsg">{res.data.message}</span>
            );
          }
        })
        .catch((error) => console.error(`Error: ${error}`));
    }
  }

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: "linear",
    slidesToShow: 1
  };

  // password hide and show
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  }

  const setOrderData = () => {
    if(router.query?.pathname){
      router.push(router.query.pathname);
      return;
    }
    const order_token = localStorage.getItem("orderToken");
    let decodeToken = null;
    if (order_token && order_token !== '') {
      decodeToken = jwtDecode(order_token);
    }
    const loginToken = localStorage.getItem('token');
    const convertedUserToken = loginToken && loginToken !== '' ? JSON.parse(loginToken) : null;
    const deadline = JSON.parse(localStorage.getItem('calculator_deadline'));

    const formData = decodeTokenToFormData(decodeToken, convertedUserToken);
    formData.append("coupon_code", decodeToken?.coupon_code || '');
    formData.append("orderMobileMenu deadlineid", decodeToken?.deadlineValue ? decodeToken.deadlineValue.value : 0);
    formData.append("speaker_notes", decodeToken.service === 2 ? decodeToken.speaker_notes : 0);

    apiHelper('setOrderV1', 'POST', formData, null).then(res => {
      if (res.data.status) {
        const data = res.data.data;
        localStorage.setItem("orderToken", data.order_token);
        localStorage.setItem('currStep', (decodeToken && decodeToken.add_detail !== '' && deadline && deadline.label !== 'Deadline' ? "2" : "1"));
        const orderPath = `${process.env.basePath}/order`;
        router.push(orderPath, orderPath, { locale: currLocale });
      }
    }).catch(error => console.error(`Error: ${error}`));
  }

  const [googleErrMsg, setGoogleErrMsg] = useState('');
  const paramLocale = DEFAULT_LOCALES_LIST.includes(currLocale) ? US : currLocale;

  const responseGoogle = async (res) => {
    setGoogleErrMsg('');
    console.log("responseGoogle", res);

    const response = await socialLoginFunc(res, 1, paramLocale);
    
    if (response.data.status) {
      setUserLoggedInData(response.data.data);
    } else {
      setGoogleErrMsg(response.data.message);
    }
  }

  const responseFailure = (res) => {
    console.log("failure Response ", res);
  }
  const handleSubmit = (event) => {
    if(router.asPath.includes("/login")) loginSubmitHandler(event);
    else if(router.asPath === "/forget-password") forgetPassword(event);
    else signUpSubmitHandler(event);
  }

  return <>
    <div className={styles.navbarBrand}>
      <Link href={'/'} locale={currLocale} className={styles.brandLink}>

        <Image src={width > 375 ? logo : mobileLogo} alt="logo"
               width={205} height={64} title="Logo" />

      </Link>
    </div>
    <section
      className={`${styles.login} p-0 ${router.asPath === "/forget-password" ? styles.forgetPwd : ""} ${router.asPath === "/signup" ? styles.signup : ""}`}
    >
      {width > 991 && (
          <div className={`${accountLeftCSS.leftImage} d-none d-lg-table-cell`}>
            <div className={accountLeftCSS.acoountImg}>
              <Slider {...settings}>
                <Accountleft
                  src={cheapestMarket}
                  alt="Cheapest in Market"
                  title="cheapest in market"
                  desc="Looking for reliable yet CheapestEssay writing services? Now get top academic services at the lowest price that doesn’t get hard on your pockets.
                  "
                />
                <Accountleft
                  src={expertWriter}
                  alt="Expert Writer’s Team"
                  title="Expert Writer’s Team"
                  desc="Worried about the writer’s capability? Rest assured, as our writers have expertise in academic writing and hold degrees from top-ranked universities.
                  "
                />
                <Accountleft
                  src={onTimeDelivery}
                  alt="On Time Delivery"
                  title="on time delivery"
                  desc="Stressed about meeting the deadlines of your assignment? Leave all your worries behind as with us; you get your order delivered at the specified date.
                  "
                />
              </Slider>
            </div>
          </div>
      )}
      <div className={styles.accountDetails}>
        <div
          className={styles.loginForm}
          // method="post"
        >
          <h1 className={`${styles.sectionTitle} section-title`}>{props.title}</h1>
          <div className={`${styles.formGroup} ${emailIsValid ? "" : styles.invalid}`}>
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter your Email"
              className={`${styles.formControl} form-control`}
              value={enteredEmail}
              onChange={emailChangeHandler}
              onBlur={validateEmailHandler}
              autoComplete="new-password"
            />
            { !emailIsValid && <p className="errorMsg">{emailInValidMsg}</p> }
          </div>
          <div className={`${styles.formGroup} ${styles.accPwd} ${passwordIsValid ? "" : styles.invalid}`}
          >
            <label>Password</label>
            <div className={`${styles.showPassword} position-relative`}>
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="Enter your Password"
                className={`${styles.formControl} form-control`}
                value={enteredPassword}
                onChange={passwordChangeHandler}
                onBlur={validatePasswordHandler}
                autoComplete="new-password"
              />
              {enteredPassword && <div className={styles.icon} onClick={togglePasswordVisiblity}>
                {
                  passwordShown ? <HidePasswordIcon /> : <ShowPasswordIcon />
                }
              </div>}
            </div>
            { !passwordIsValid && <p className="errorMsg">{passwordInValidMsg}</p> }
          </div>
          <div className={`d-flex align-items-center justify-content-end ${styles.centerPart}`}>
            <Link href={`/forget-password`} locale={false} className={styles.forgetPwd}>
              
                Forget Password?
              
            </Link>
          </div>
          {forgotPwdSuccessmsg}
          {forgotPwdErrormsg}
          <p className="errorMsg">{detailIsValid}</p>
          <div className={styles.btns}>
            <button
              type="submit"
              className="btn secondary-btn w-100"
              disabled={!formIsValid || apiLoader}
              onClick={ handleSubmit }
            >
              {props.btntext}
            </button>
          </div>
          <div className={styles.bottomPart}>
            <p className={`${styles.textOr} text-center d-block mt-4 mb-4`}>
              <span>or continue with</span>
            </p>
            <div className={`${styles.connection} d-flex justify-content-center mb-1`}>
              <ReactGoogleLogin
                  clientId="1022764320593-e6r3d8qpdhmqfmrvphqms8jsah9nkoap.apps.googleusercontent.com" // We created this earlier, remember?
                  buttonText="Login with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseFailure}
              />
             {/* <SocialButton
                provider="google"
                appId='1022764320593-e6r3d8qpdhmqfmrvphqms8jsah9nkoap.apps.googleusercontent.com'
                onLoginSuccess={responseGoogle}
                // onLoginFailure={responseGoogleFailure}
                className={styles.links}
                cookiepolicy='single_host_origin'
              >
                <Img
                  src="/account/google.svg"
                  alt="google"
                  width={20}
                  height={20}
                />
                <span className={styles.text}>Google</span>
              </SocialButton>*/}
              {/* <SocialButton
                provider="facebook"
                appId='1904009586394982'
                onLoginSuccess={responseFacebook}
                onLoginFailure={responseFacebookFailure}
                className="links"
              >
                <Img
                  src="/account/fb.svg"
                  alt="facebook"
                  width={20}
                  height={20}
                />
                <span className="text">Facebook</span>
              </SocialButton> */}
            </div>
            <p className="errorMsg text-center">{googleErrMsg}</p>
            {/*<p className="errorMsg text-center">{fbErrMsg}</p>*/}
          </div>
          <div className={`text-center ${styles.linkAccount}`}>
            {props.linktext}
            <a className={styles.text} href={props.link} target="_self">
              {props.text}
            </a>
            <ToastContainer autoClose={5000} />
          </div>
        </div>
      </div>
    </section>
  </>;
}

export default Account;